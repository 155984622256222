<template>
  <div class="d-flex mb-2">
    <div class="flex-fill">
      <div class="input-group">
        <search />
      </div>
    </div>

    <div class="ms-2">
      <div style="min-width: 90px" class="d-flex justify-content-between">
        <span
          class="d-inline-block"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :data-bs-original-title="$t('top_accounts.previous_page')"
          v-tooltip
        >
          <button
            class="btn text-primary border"
            @click="prevPage()"
            :disabled="disablePrevBtn"
          >
            <i class="bi bi-arrow-left-circle"></i></button
        ></span>
        <span
          class="d-inline-block"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :data-bs-original-title="$t('top_accounts.next_page')"
          v-tooltip
        >
          <button
            class="btn text-primary border"
            @click="nextPage()"
            :disabled="disableNextBtn"
          >
            <i class="bi bi-arrow-right-circle"></i></button
        ></span>
      </div>
    </div>
  </div>
<div class="list-group">
  <account-summary :topAccsPage="true" />
    </div>
  <div class="py-2 px-3 border rounded d-flex font-monospace my-2">
    <div class="flex-grow-1 small align-self-center text-muted">
      {{ $t("top_accounts.showing") }}<b>{{ topAccounts?.from }}</b
      >{{ $t("top_accounts.to") }}<b>{{ topAccounts?.to }}</b
      >{{ $t("top_accounts.of") }}<b>{{ topAccounts?.total }}</b>
    </div>
    <div class="flex-grow-0">
      <select
        class="form-select form-select-sm text-muted"
        aria-label=".form-select-sm example"
        v-model="paginate"
        @change="onChangePagination()"
      >
        <option v-for="(option, idx) in options" :value="option" :key="idx">
          {{ option + $t("top_accounts.rows") }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";
import { capitalize } from "@/utils/string.js";
import Search from "@/components/Search.vue";
import AccountSummary from "@/components/summaries/AccountSummary.vue";
export default defineComponent({
  name: "TopAccounts",
  components: { Search, AccountSummary },
  setup() {
    const store = useStore();
    const paginate = ref(25);
    const options = ref([25, 50, 100]);
    store.dispatch("accounts/fetchTopAccounts", {
      page: 1,
      paginate: paginate.value,
    });

    const onChangePagination = () => {
      store.dispatch("accounts/fetchTopAccounts", {
        page: 1,
        paginate: paginate.value,
      });
    };

    const topAccounts = computed(
      () => store.getters["accounts/getTopAccounts"]
    );
    const nextPage = () => {
      store.dispatch("accounts/fetchTopAccounts", {
        page: topAccounts.value.page + 1,
        paginate: paginate.value,
      });
    };
    const prevPage = () => {
      store.dispatch("accounts/fetchTopAccounts", {
        page: topAccounts.value.page - 1,
        paginate: paginate.value,
      });
    };
    const disablePrevBtn = computed(() => topAccounts.value.page === 1);
    const disableNextBtn = computed(
      () => topAccounts.value.page === topAccounts.value.lastPage
    );

    return {
      capitalize,
      nextPage,
      prevPage,
      disablePrevBtn,
      disableNextBtn,
      paginate,
      onChangePagination,
      options,
      topAccounts,
    };
  },
});
</script>

<style>
</style>
